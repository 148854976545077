
jQuery(document).ready(function () {
    // console.log('checkout.js loaded');
    

    function isCheckoutPage() {
        return jQuery('body').hasClass('woocommerce-checkout');
    }

    if(isCheckoutPage()) {
        const targetNode = document.body;
        const config = { childList: true, subtree: true };
    
        const observer = new MutationObserver((mutations, observerInstance) => {
        const checkoutBlock = document.querySelector('.wc-block-checkout__login-prompt');
        if (checkoutBlock) {
            const loginLink = document.querySelector('.wc-block-checkout__login-prompt');
            const loginModal = document.querySelector('.checkout-login-modal');
            const loginClose = document.querySelector('.checkout-login-modal .close-login-modal');
            console.log(loginLink);
            console.log(loginModal);

            if(loginLink && loginModal) {
                console.log('login link and modal found, binding event');
                
                loginLink.addEventListener('click', function(e) {
                    e.preventDefault();
                    loginModal.classList.toggle('active');
                });

                loginClose.addEventListener('click', function(e) {
                    e.preventDefault();
                    loginModal.classList.remove('active');
                });
            }

            observerInstance.disconnect();
        }
        });
        observer.observe(targetNode, config);
        
    }

});
