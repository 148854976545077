
jQuery(document).ready(function () {

    const free_delivery_threshold = 60;


    const {select, subscribe} = window.wp.data;
    const cartStoreKey = window.wc.wcBlocksData.CART_STORE_KEY;
    const unsub = subscribe( onCartChange, cartStoreKey );

    function onCartChange() {
        const cart = select( cartStoreKey ).getCartData();
        console.log(cart.totals);
        
        let cart_new_price = parseFloat(cart.totals.total_items) + parseFloat(cart.totals.total_items_tax);
        cart_new_price = cart_new_price / 100;
        console.log( 'Cart change, new total price : ', cart_new_price );

        
        let remaining = free_delivery_threshold - cart_new_price;
        if(remaining < 0) {
            remaining = 0;
        }

        const free_delivery_elements = document.querySelectorAll('.freedelivery_container');
        free_delivery_elements.forEach( element => {
            const price_element = element.querySelector('.price-missing');
            const missing_elements = element.querySelectorAll('.missing');
            const reached_elements = element.querySelectorAll('.reached');

            price_element.innerHTML = remaining.toFixed(2);

            if (cart_new_price < free_delivery_threshold) {
                reached_elements.forEach( el => {
                    el.classList.remove('show');
                });
                missing_elements.forEach( el => {
                    el.classList.add('show');
                });
            } else {
                missing_elements.forEach( el => {
                    el.classList.remove('show');
                });
                reached_elements.forEach( el => {
                    el.classList.add('show');
                });
            }
        });
    }

    function updateDeliveryElements(cart_new_price) {
        let remaining = free_delivery_threshold - cart_new_price;
        if(remaining < 0) {
            remaining = 0;
        }

        const free_delivery_elements = document.querySelectorAll('.freedelivery_container');
        free_delivery_elements.forEach( element => {
            const price_element = element.querySelector('.price-missing');
            const missing_elements = element.querySelectorAll('.missing');
            const reached_elements = element.querySelectorAll('.reached');

            price_element.innerHTML = remaining.toFixed(2);

            if (cart_new_price < free_delivery_threshold) {
                reached_elements.forEach( el => {
                    el.classList.remove('show');
                });
                missing_elements.forEach( el => {
                    el.classList.add('show');
                });
            } else {
                missing_elements.forEach( el => {
                    el.classList.remove('show');
                });
                reached_elements.forEach( el => {
                    el.classList.add('show');
                });
            }
        });
    }


    // On cart add or remove
    // jQuery(document.body).on('updated_cart_totals', function() {
    //     console.log('updated_cart_totals');
        
    // });

    jQuery(document.body).on('added_to_cart', function () {
        setTimeout(() => {
            onCartUpdate();
        }, 1000);
    });
    jQuery(document.body).on('removed_from_cart', function () {
        setTimeout(() => {
            onCartUpdate();
        }, 1000);
    });

    function onCartUpdate() {
        console.log('Cart change');

        const mini_cart_total = document.querySelector('.mini-cart-container .total bdi');
        let cart_total = mini_cart_total.innerHTML;
        // Remove span
        cart_total = cart_total.replace(/<span class="woocommerce-Price-currencySymbol">€<\/span>/g, '');
        console.log('Cart total: ', cart_total);
        cart_total = cart_total.replace(/&nbsp;/g, '');
        console.log('Cart total: ', cart_total);
        cart_total = cart_total.replace(/,/g, '.');
        console.log('Cart total: ', cart_total);
        cart_total = parseFloat(cart_total);

        console.log('Cart total: ', cart_total);
        updateDeliveryElements(cart_total);
    }
});
