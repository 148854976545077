
jQuery(document).ready(function () {
    const cart_icon = jQuery('.navbar_container .btn_panier');
    const mini_cart = jQuery('.navbar_container .mini-cart-container');
    const closeBtn = jQuery('.navbar_container .mini-cart-container .close');
    const navCountElement = document.querySelector('.btn_panier .cart-contents-count');
    const singleAddToCart = document.querySelector('.single_add_to_cart_button');
    let closeTimeout;

    function isCartPage() {
        const is_cart = jQuery('body').hasClass('woocommerce-cart');
        const is_checkout = jQuery('body').hasClass('woocommerce-checkout');
        // return is_cart || is_checkout;
        return jQuery('body').hasClass('woocommerce-cart');
    }

    // Function to open the mini cart
    function openMiniCart() {
        if (!isCartPage()) {
            mini_cart.addClass('show');
        }
    }

    // Function to close the mini cart with a delay
    function closeMiniCartWithDelay() {
        closeTimeout = setTimeout(() => {
            mini_cart.removeClass('show');
        }, 500);
    }

    // Clear the timeout if the user hovers back in
    function cancelCloseMiniCart() {
        clearTimeout(closeTimeout);
    }

    // Open mini cart when hovering the cart icon
    cart_icon.mouseenter(function () {
        openMiniCart();
    });

    // Close mini cart when mouse leaves both the cart icon and the mini cart
    cart_icon.mouseleave(closeMiniCartWithDelay);
    mini_cart.mouseleave(closeMiniCartWithDelay);

    // Cancel close when hovering over mini cart or cart icon
    mini_cart.mouseenter(cancelCloseMiniCart);

    // Close mini cart when clicking the close button
    closeBtn.click(function () {
        mini_cart.removeClass('show');
    });

    // Automatically open mini cart when a product is added to the cart
    jQuery(document.body).on('added_to_cart', function () {
        console.log('EVENT: added_to_cart');
        
        openMiniCart();

        setTimeout(() => {
            mini_cart.scrollTop(mini_cart.prop('scrollHeight'));
        }, 200);

        updateCartItemCount();
    });

    // Automatically update cart item count when a product is removed from the cart
    jQuery(document.body).on('removed_from_cart', function () {
        console.log('EVENT: removed_from_cart');
        updateCartItemCount();
    });

    // Update cart count when cart totals are updated (e.g., on cart page after changing quantities)
    jQuery(document.body).on('updated_cart_totals', function () {
        console.log('EVENT: updated_cart_totals');
        updateCartItemCount();
    });

    // Manually trigger update when the page loads to ensure the cart count is accurate
    updateCartItemCount();


    // Function to update the cart item count
    function updateCartItemCount() {
        jQuery.post('/?wc-ajax=get_refreshed_fragments', function (response) {
            if (response && response.fragments && response.fragments['div.widget_shopping_cart_content']) {
                const cartWidgetContent = jQuery(response.fragments['div.widget_shopping_cart_content']);
                const itemCountElements = cartWidgetContent.find('span.quantity');

                let totalItemCount = 0;

                itemCountElements.each(function() {
                    const quantityText = jQuery(this).text().trim(); // Example: "1 ×"
                    const quantity = parseInt(quantityText.split('×')[0].trim()); // Extract the number part before "×"
                    
                    if (!isNaN(quantity)) {
                        totalItemCount += quantity;
                    }
                });

                console.log('Total cart item count:', totalItemCount);

                // Update the count in the navigation
                if (navCountElement && totalItemCount !== parseInt(navCountElement.textContent)) {
                    navCountElement.textContent = totalItemCount;
                }
            }
        });
    }


    // Manage single add to cart button
    // if (singleAddToCart) {
    //     singleAddToCart.addEventListener('click', function(e) {
    //         e.preventDefault();
    //         console.log('Single add to cart button clicked');
    //         // openMiniCart();
    //         // updateCartItemCount();
    //     });
    // }

    // if (singleAddToCart) {
    //     singleAddToCart.addEventListener('click', function (event) {
    //         event.preventDefault();
    
    //         let productForm = jQuery('.cart');  // Get the form for the single product
    
    //         jQuery.ajax({
    //             url: wc_add_to_cart_params.ajax_url,  // WooCommerce AJAX URL
    //             type: 'POST',
    //             data: productForm.serialize(),  // Serialize form data for submission
    //             success: function (response) {
    //                 if (response.error && response.product_url) {
    //                     window.location = response.product_url;  // Redirect if an error occurred
    //                     return;
    //                 }
    
    //                 // Trigger the 'added_to_cart' event on successful AJAX add to cart
    //                 jQuery(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, singleAddToCart]);
    
    //                 // Optionally, you could auto-open the mini cart here
    //                 // openMiniCart();  // Open the mini cart after adding the product
    //                 // updateCartItemCount();  // Update the cart item count in the nav
    //             },
    //             error: function (xhr, status, error) {
    //                 console.log("Error adding product to cart:", error);
    //             }
    //         });
    //     });
    // }
    



    // (function() {
    //     const allEvents = [];
    
    //     // Detect all event types dynamically
    //     for (let key in document) {
    //         if (key.startsWith('on')) {
    //             allEvents.push(key.slice(2));
    //         }
    //     }
    
    //     // Exclude certain event types
    //     const eventsToIgnore = ['mousemove', 'mouseenter', 'mouseleave', 'mouseout', 'mouseover', 'pointermove', 'pointerenter', 'pointerleave', 'pointerout', 'pointerover', 'pointerrawupdate', 'load', 'scroll', 'scrollend', 'wheel'];
    
    //     const logEvent = (event) => {
    //         if (!eventsToIgnore.includes(event.type)) {
    //             console.log(`Event triggered: ${event.type}`, event);
    //         }
    //     };
    
    //     // Add event listener for each detected event type, except ignored ones
    //     allEvents.forEach(eventType => {
    //         if (!eventsToIgnore.includes(eventType)) {
    //             document.addEventListener(eventType, logEvent, true);
    //         }
    //     });
    
    //     console.log('Listening for the following events (excluding mousemove, mouseenter, and mouseleave):', allEvents.filter(event => !eventsToIgnore.includes(event)));
    // })();
    
    // IN CART PAGE : Hide .woocommerce-shipping-destination if Bon cadeau
    const shippingDestination = document.querySelector('.woocommerce-shipping-destination');
    const shippingCaclulator = document.querySelector('.woocommerce-shipping-calculator');
    const shippingMethods = document.querySelector('ul#shipping_method');
    const freeShippingMethods = ['free_shipping:156', 'free_shipping:157', 'free_shipping:158', 'free_shipping:159'];

    if (shippingMethods) {

        function hideShippingDestination() {
            const selectedShippingMethod = shippingMethods.querySelectorAll('input[name="shipping_method[0]"]');
            console.log(selectedShippingMethod);
            
            const nb_methods = selectedShippingMethod.length;
            let nb_included = 0;
            selectedShippingMethod.forEach(function (input) {
                if (freeShippingMethods.includes(input.value)) {
                    nb_included++;
                }
            });

            if (nb_included === nb_methods) {
                shippingDestination.style.display = 'none';
                shippingCaclulator.style.display = 'none';
            }
        }
        hideShippingDestination();

    }
    
});
