jQuery(document).ready(function() {
    jQuery('body').on('click', '.arrondir_note', function() {
        // const arrondi = parseFloat(jQuery(this).data('arrondi'));
        // arrondi_virgule = arrondi.toString().replace('.', ',');

        cart_total = jQuery('.wc-block-components-totals-item__value span').html();
        cart_total = cart_total.replace(/&.*>/, '');
        cart_total = cart_total.replace(',', '.');
        cart_total = parseFloat(cart_total);

        montant_arrondi = 1 - (parseFloat(cart_total) % 1);
        montant_arrondi = parseFloat(montant_arrondi).toPrecision(2);
        arrondi_virgule = montant_arrondi.replace('.', ',');
        
        jQuery('.wdgk_donation').val(arrondi_virgule);
        jQuery('.wdgk_add_donation')[0].click();
    });
});